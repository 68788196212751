import axios from "axios";
import {
    getParcels,
    getAreas,
    selectMunicipality,
    setFarms,
    userFarms,
    uploadParcelImages
} from "../constants/api_endpoints";
import {getCookie} from "../utils/requests";


export const get_municipalities = async (muni_search) => {
    try {
        const res = await axios.get(`${getAreas}?municipality_search=${muni_search}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const select_municipality = async (municipality) => {
    try {
        console.log("token is ", getCookie('csrftoken'))
        const res = await axios.post(`${selectMunicipality}?municipality_search=${municipality}`, {}, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res.data
    } catch (err) {
        return err
    }
}

export const get_farms = async (farm_search) => {
    try {
        const res = await axios.get(`${getAreas}?farm_search=${farm_search}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const set_farms = async (selectedFarms, userId) => {
    try {
        const res = await axios.post(`${setFarms}`, {
            selected_farms: selectedFarms,
            user_id: userId
        }, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res.data
    } catch (err) {
        return err
    }
}

export const getUserFarms = async (userName=null) => {
    try {
        let url = userFarms
        if (userName)
            url += '?user_name='+userName
        const res = await axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res.data
    } catch (err) {
        return err
    }
}

export const get_parcels = async (codigoId) => {
    try {
        const res = await axios.get(`${getParcels}?codigo=${codigoId}`)
        return res.data
    } catch (err) {
        return err
    }
}


export const upload_parcel_image = async (parcelImageData)=> {
    try {
        const res = await axios.post(uploadParcelImages, parcelImageData, {
            withCredentials: true,
            headers: {
                sessionid: getCookie('sessionid'),
                "Content-Type": "application/form-data",
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res.data
    } catch (err) {
        return err
    }
}
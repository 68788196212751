import {useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

export const MapController = ({
                                  mapLayerClickedHandler,
                                  parcelsGeoData,
                                  toggledParcel,
                                  highlightedParcel
                              }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-48.689755);
    const [lat, setLat] = useState(-6.622128);
    const [zoom, setZoom] = useState(9);

    useEffect(() => {
        initializeMap()
    }, [mapContainer])

    useEffect(() => {
        if (map.current) {
            map.current.on('click', ((event) => {
                const features = map.current.queryRenderedFeatures(event.point)
                if (features.length) {
                    mapLayerClickedHandler(features[0].properties)
                }
            }))
        }
    }, [map])

    useEffect(() => {
        console.log('togggled parcel is ', toggledParcel)
        if (toggledParcel?.checked === true)
            addGeoJSONFarm(parcelsGeoData.new[toggledParcel.index])
        else if (toggledParcel?.checked === false)
            removeGeoJSONFarm(toggledParcel.id)
    }, [toggledParcel])

    const initializeMap = () => {
        if (map.current) return; // initialize map only once
        mapboxgl.accessToken = "pk.eyJ1IjoibmFiZWVsMzgiLCJhIjoiY2w5MDhjeHcyMDJoNzNucGIzZWt2MjNqYiJ9.R8LakDjXjIACF2sInXVN1Q";
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v10',
            center: [lng, lat],
            zoom: zoom
        });
    }

    const removeGeoJSONFarm = (farmId) => {
        map.current.removeLayer(farmId)
        map.current.removeLayer(farmId + '_outline')
        map.current.removeSource(farmId)
    }

    const addGeoJSONFarm = (geoJsonData) => {
        const _map = map.current
        const featureId = geoJsonData.features[0].properties.pk
        _map.addSource(featureId, {
            type: 'geojson',
            data: geoJsonData
        });
        _map.addLayer({
            'id': featureId,
            'type': 'fill',
            'source': featureId, // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0
            }
        });
        _map.addLayer({
            'id': featureId + '_outline',
            'type': 'line',
            'source': featureId, // reference the data source
            'layout': {},
            'paint': {
                'line-color': '#ffffff', // blue color fill
                'line-opacity': 1,
                'line-width': 2
            }
        })

        // Change the cursor to a pointer when the mouse is over the states layer.
        _map.on('mouseenter', featureId, function () {
            _map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        _map.on('mouseleave', 'states-layer', function () {
            _map.getCanvas().style.cursor = '';
        });
    }

    useEffect(() => {
        if (map.current)
            handleParcelsChange(parcelsGeoData.new, parcelsGeoData.old)
    }, [map.current, parcelsGeoData.new])

    useEffect(() => {
        if (highlightedParcel && map.current) {
            console.log("highlighted parcel is ", highlightedParcel)
            let bounds = new mapboxgl.LngLatBounds();
            for (const feature of highlightedParcel?.features) {
                const coords = feature.geometry.coordinates[0]
                coords.map((coord) => {
                    bounds.extend(new mapboxgl.LngLat(coord[0], coord[1]))
                });
            }
            map.current.fitBounds(bounds);
        }
        else {
            fitToGeoJSONBounds(parcelsGeoData?.new)
        }
    }, [highlightedParcel, parcelsGeoData])

    const fitToGeoJSONBounds = (parcels)=> {
        if (parcels.length) {
            let bounds = new mapboxgl.LngLatBounds();
            parcels.forEach((layer) => {
                if (layer.features.length) {
                    const coords = layer.features[0].geometry.coordinates[0]
                    coords.map((coord) => {
                        bounds.extend(new mapboxgl.LngLat(coord[0], coord[1]))
                    });
                }
            })
            map.current.fitBounds(bounds);
        }
    }

    const handleParcelsChange = (newParcels, oldParcels) => {
        try {
            oldParcels.forEach((layer) => {
                removeGeoJSONFarm(layer.features[0].properties.pk)
            })
        } catch (err) {
            console.log(err)
        }

        if (newParcels.length) {
            let bounds = new mapboxgl.LngLatBounds();
            newParcels.forEach((layer) => {
                if (layer.features.length) {
                    const coords = layer.features[0].geometry.coordinates[0]
                    coords.map((coord) => {
                        bounds.extend(new mapboxgl.LngLat(coord[0], coord[1]))
                    });
                    if (map.current.getLayer(layer.features[0].properties.pk) === undefined)
                        addGeoJSONFarm(layer)
                }
            })
            map.current.fitBounds(bounds);
        }
    }

    return (
        <div ref={mapContainer} id='map' className="map" style={{
            height: "100%"
        }}>
        </div>
    )
}
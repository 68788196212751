import Alert from '@mui/material/Alert';
import {AlertTitle, Grid} from "@mui/material";

export const AlertPage = ({
                              showAlert = false,
                              type = "success",
                              message = ""
                          }) => {
    return (
        <>
            {
                showAlert ?
                    <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="center-fixed alert"
                    >

                        <Alert
                            severity={type}>
                            <AlertTitle>{type.toUpperCase()}</AlertTitle>
                            {message}
                        </Alert>
                    </Grid>
                    :
                    (
                        ""
                    )
            }
        </>
    )
}
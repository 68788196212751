import React from "react";

export const MyFarms = ({
                            setToggledParcel,
                            selectedParcels
                        }) => {
    return (
        <>
            <div className="farm-selection-container">
                <p className="x-small-text">
                    Inclui o seguinte:
                </p>
                {selectedParcels.length > 0 &&
                    <div
                        id="farms-selection-area" className="farms-selection full-page-height">
                        {
                            selectedParcels.map((parcel, index) => {
                                return (
                                    <>
                                <span>
                                        <input
                                            className="farm-checkbox pointer-cursor"
                                            id={parcel?.parcelId}
                                            key={parcel?.parcelId}
                                            name={parcel?.parcelName}
                                            type="checkbox"
                                            checked={parcel?.checked}
                                            onChange={(event) => setToggledParcel(index,
                                                parcel?.parcelId,
                                                event.target.checked)}
                                        />
                                        <span className="ml-5 x-small-text">
                                            {parcel?.parcelName}
                                        </span>
                                    </span>
                                        <br/>
                                    </>
                                )
                            })
                        }
                    </div>
                }
            </div>

        </>
    )
}
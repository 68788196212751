import React, {useState, useEffect} from "react";
import {registerUser} from "../../services/auth_service";
import cookie from "react-cookies"


export const Register = ({
                             handleSuccess = () => {
                             },
                             handleFailedRegister
                         }) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const handleRegisterSubmit = async () => {
        const form = new FormData()
        form.append('name', name)
        form.append('username', email)
        form.append('email', email)
        form.append('password1', password1)
        form.append('password2', password2)
        const res = await registerUser(form)
        if (res?.data?.status === 200) {
            resetFields()
            handleSuccess(res?.data?.data?.message, res?.data?.data?.id)
        } else {
            const errorsInfo = res?.response?.data?.error
            handleFailedRegister(`${Object.keys(errorsInfo)[0]}: ${Object.values(errorsInfo)[0]}`)
        }
        console.log("res is ", res)
    }

    const resetFields = () => {
        setPhone('')
        setName('')
        setEmail('')
        setPassword1('')
        setPassword2('')
    }

    return (
        <div>
            <form
                onSubmit={(event) => event.preventDefault()}
                className="m-10">
                <input
                    type="hidden"
                    value={cookie.load("csrftoken")}
                    name="csrfmiddlewaretoken"
                />
                <label>Nome Completo*</label>
                <br/>
                <input
                    className="full-width"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required={true}
                />
                <br/>
                <br/>
                <label>
                    Telefone
                </label>
                <br/>
                <input
                    className="full-width"
                    name="phone"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                />
                <br/>
                <br/>
                <label>E-mail*</label>
                <br/>
                <input
                    className="full-width"
                    name="email"
                    required={true}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <br/>
                <br/>
                <label>Senha*</label>
                <br/>
                <input
                    className="full-width"
                    name="password"
                    required={true}
                    value={password1}
                    onChange={(event) => setPassword1(event.target.value)}
                />
                <br/>
                <br/>
                <label>Confirmar senha*</label>
                <br/>
                <input
                    className="full-width"
                    name="password2"
                    required={true}
                    value={password2}
                    onChange={(event) => setPassword2(event.target.value)}
                />
                <br/>
                <br/>
                <div className="registration-submit-btn-container">
                    <input
                        className="skoog-button"
                        type="submit"
                        value="Enviar"
                        onClick={handleRegisterSubmit}
                    />
                </div>
            </form>
            <div className="m-10 x-small-text">
                <p>
                    Ao clicar enviar, você confirma que está de acordo com nossa
                    <br/>
                     <a className="text-yellow" target="_blank" href="https://www.meucarbono.com/privacidade">Política de Privacidade e Termos de Uso.</a>
                </p>
                <p>
                    Você receberá um e-mail de confirmação com um link. Clique no
                    link enviado para finalizar seu cadastro.
                </p>
                <p>
                    Atenção: caso o e-mail não esteja em sua caixa de entrada,
                    verifique se ele não se encontra na pasta “Spam” ou “junk”.
                </p>
            </div>
        </div>
    )
}
import axios from "axios";
import {getUserId, login, logout, register} from "../constants/api_endpoints";
import {getCookie} from "../utils/requests";
import {BACKEND_URL} from "../constants/app";


const client = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: true,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
})

export const registerUser = async (data) => {
    try {
        const res = await client.post(register, data, {
            headers: {
                "Content-Type": 'multipart/form-data',
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res
    } catch (err) {
        return err
    }
}

export const loginUser = async (data) => {
    try {
        const res = await client.post(login, data, {
            headers: {
                "Content-Type": 'multipart/form-data',
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
        return res
    } catch (err) {
        return err
    }
}

export const logoutUser = async () => {
    try {
        return  await client.post(logout, {}, {
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
    } catch (err) {
        return err
    }
}

export const userId = async (userName) => {
    try {
        return  await client.get(`${getUserId}?username=`+userName, {
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        })
    } catch (err) {
        return err
    }
}


import * as React from 'react';
import {useState, useEffect} from "react";
import "../../styles/inputs.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariants({
                                           optionLabel = "",
                                           selectedOption = null,
                                           options = [],
                                           setSelectedOption
                                       }) {
    return (
        <div>
            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="demo-simple-select-standard-label">{optionLabel}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedOption}
                    onChange={(event) => setSelectedOption(event.target.value)}
                    label="Age"
                >
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {
                        options.map((option) => {
                            return (
                                <MenuItem value={option.value}>{option.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>
    );
}

export const DropDown = ({
                             optionLabel = "",
                             selectedOption = null,
                             options = [],
                             setSelectedOption

                         }) => {
    const [onDropDown, setOnDropDown] = useState(false)
    return (
        <>
            <div>
                <label className="form-item form-item-label">
                    {optionLabel}
                </label>
            </div>
            <div className="mun-list pos-relative">
                <ul id="options-list" className="overlay">
                    <div
                        className="dropdown-container"
                        onMouseEnter={() => {
                            setOnDropDown(true)
                        }}
                        onMouseLeave={() => {
                            setOnDropDown(false)
                        }}
                    >
                        {
                            options.map((option) => {
                                return (
                                    <li
                                        onClick={() => setSelectedOption(option.value)}
                                    >
                                        <a>{option.label}</a>
                                    </li>
                                )
                            })
                        }
                    </div>
                </ul>
            </div>
        </>
    )
}

export const DropDownSimple = ({
                                   optionLabel = "",
                                   selectedOption = null,
                                   options = [],
                                   setSelectedOption

                               }) => {
    return (
        <>
            <label>{optionLabel}</label>
            <select name={optionLabel} id={optionLabel} onChange={(event)=> setSelectedOption(event.target.value)}>
                <option value={null}>None</option>
                {
                    options.map((option) => {
                        return (
                            <option value={option.value}>{option.label}</option>
                        )
                    })
                }
            </select>
        </>
    )}


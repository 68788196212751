import React, {useState, useEffect, useContext, useMemo} from "react";
import "../styles/homepage.css"
import "../styles/style.css"
import farmerIcon from "../assets/farmer-icon.png";
import {MapController} from "./map";
import {useCookies} from 'react-cookie'
import {Tooltip, useMediaQuery} from "@mui/material";
import {getCsvParcels} from "../services/project_service";
import {AppContext} from "../context/AppContext";
import closeIcon from "../assets/cross.png";


export const ProjectsView = () => {
    const {toggleLoader} = useContext(AppContext)
    const [selectedFarm, setSelectedFarm] = useState(null)
    const [parcelsData, setParcelsData] = useState([])
    const [farmText, setFarmText] = useState("")
    const mobileScreen = useMediaQuery('(max-width: 500px)')

    useEffect(() => {
        ;(async () => {
            toggleLoader(true)
            const projectsResp = await getCsvParcels()
            if (projectsResp?.status === 200) {
                setParcelsData(projectsResp?.data.map(farm => {
                    return {
                        ...farm,
                        geometry: JSON.parse(farm.geometry)
                    }
                }))
            }
            console.log("parcels data is ", parcelsData)

            toggleLoader(false)
        })()
    }, [])

    const parcelsForMap = useMemo(() => {
        if (parcelsData.length) {
            return {
                new: parcelsData.map(parcelInfo => parcelInfo.geometry),
                old: []
            }
        }
        return {
            old: [],
            new: []
        }
    }, [parcelsData])

    const farmsArea = useMemo(() => {
        if (parcelsData.length) {
            return parcelsData.reduce((accumulated, currentValue, currentIndex, []) => {
                return accumulated + currentValue['area']
            }, 0)
        }
        return 0
    }, [parcelsData])

    const handleMapLayerClick = (layerInfo) => {
        console.log("layer clicked is ", layerInfo)
        // setSelectedParcelForUpload(layerInfo?.pk)
    }

    const filteredFarms = useMemo(() => {
        if (farmText.length)
            return parcelsData.filter(parcel => parcel.farms.includes(farmText))
        return parcelsData
    }, [farmText, parcelsData])

    const ParcelCsvItem = ({farmInfo, clickHandler, area, hoverEnabled=false}) => {
        return <div
            className={`parcel-csv-item ${hoverEnabled ? 'hover-enabled' : 'selected'}`}
            onClick={clickHandler}
        >
            <img
                className="pointer-cursor"
                width={30} src={farmerIcon} alt="farmer icon"/>
            <div>
                <Tooltip title={farmInfo?.farms}>
                    <div className="truncated-text">
                        {farmInfo?.farms}
                    </div>
                </Tooltip>
                <span>
                    {farmInfo?.municipality}
                </span>
                <span>
                    {area && area}
                </span>
            </div>
            <div>
                <img/>
            </div>
        </div>
    }

    const handleFarmClick = (farmInfo) => {
        setSelectedFarm(farmInfo)
    }

    return (
        <div className="homepage app-font">
            <div className={`left-panel left-panel-csv projects no-scrollbar ${mobileScreen ? 'mobile' : ''}`}>
                <div className="p-10 dark-green-text">
                        <span className="bold-text">
                            Sistemas silvopastoris – Norte de Minas
                        </span>
                </div>
                {selectedFarm ?
                    <div className="mt-20">
                        <div className="pos-relative">
                            <ParcelCsvItem farmInfo={selectedFarm} clickHandler={() => {
                            }}/>
                            <img
                                onClick={() => {
                                    setSelectedFarm(null)
                                    setFarmText("")
                                }}
                                className="cursor-pointer close-icon-csv-farm"
                                src={closeIcon} width="20px" height="20px" alt="close"/>
                        </div>

                        <p className="pl-20 pr-10 black-text text-small font-bold">
                            Proprietário: {selectedFarm.landowner}
                            <br/>
                            Data de cadastro: dd/mm/yyy
                            <br/>
                            Área: {selectedFarm.area.toFixed(2)} hectares
                            <br/>
                            Espécies: Macaúba
                            <br/>
                            Projeção de sequestro de carbono: xx tCO2eq/ano
                        </p>
                    </div>
                    :
                    <>
                        <div className="p-10 dark-green-text">
                            <p className="pl-20 text-x-small">
                                {parcelsData.length} farms
                                <br/>
                                {farmsArea.toFixed(2)} hectares total area
                            </p>
                        </div>
                        <div className="flex justify-center align-text-center p-10">
                        <span className="bold-text black-text">
                            Buscar
                        </span>
                            <input
                                onChange={(event) => {
                                    setFarmText(event.target.value.toUpperCase())
                                }}
                                className="projects-farms-input" placeholder="Farm name"
                            />
                        </div>
                        <div className="mt-5 csv-parcels-container scrollable-y">
                            {
                                filteredFarms.map((farmInfo) => {
                                    return (
                                        <div
                                            className="parcel-csv-item hover-enabled"
                                            onClick={() => handleFarmClick(farmInfo)}
                                        >
                                            <img width={30} src={farmerIcon} alt="farmer icon"/>
                                            <div>
                                                <Tooltip title={farmInfo?.farms}>
                                                    <div className="truncated-text">
                                                        {farmInfo?.farms}
                                                    </div>
                                                </Tooltip>
                                                <span>
                                                {farmInfo?.municipality}
                                            </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </div>
            {/*<div className="map-csv-parcels">*/}
            <MapController
                mapLayerClickedHandler={handleMapLayerClick}
                parcelsGeoData={parcelsForMap}
                toggledParcel={null}
                highlightedParcel={selectedFarm ? selectedFarm.geometry : null}
            />
            {/*</div>*/}
        </div>
    )
}
import axios from "axios";
import {csvParcels, project} from "../constants/api_endpoints";


export const getAllProjects = async () => {
    try {
        const res = await axios.get(project)
        return res.data
    } catch (err) {
        return err
    }
}


export const getCsvParcels = async (csvName="Silvopastoril.csv") => {
    try {
        const res = await axios.get(`${csvParcels}?csv_name=${csvName}`)
        return res.data
    } catch (err) {
        return err
    }
}

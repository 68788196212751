import React, {useState, useEffect} from "react";
import "../../styles/inputs.css";


export const Inputs = ({
    removeMuniDropDown,
    removeFarmDropDown,
                           setSearchedMuni,
                           municipalitiesList,
                           farmsList,
                           setSearchedFarm,
                           searchedFarm,
                           searchedMuni,
                           municipalitySelectedHandler,
                           farmSelectedHandler,
                           selectedParcels,
                           parcelToggleHandler
                       }) => {
    const [onDropDown, setOnDropDown] = useState(false)
    return (
        <>
            <div>
                <label className="form-item form-item-label" htmlFor="municipality">
                    Municipio da propriedade
                </label>
                <input
                    className="form-item"
                    type="text"
                    list="mun_list"
                    id="municipality"
                    value={searchedMuni}
                    onBlur={()=> {
                        if (!onDropDown)
                            removeMuniDropDown()
                    }}
                    onInput={(event) => setSearchedMuni(event.target.value)}
                    autoComplete="off"/>
            </div>
            <div className="mun-list pos-relative">
                <ul id="mun_list" className="overlay">
                    <div
                        className="dropdown-container"
                        onMouseEnter={()=> {
                            setOnDropDown(true)
                        }}
                        onMouseLeave={()=> {
                            setOnDropDown(false)
                        }}
                    >
                        {
                            municipalitiesList.map((municipality) => {
                                return (
                                    <li
                                        onClick={() => municipalitySelectedHandler(municipality)}
                                    >
                                        <a>{municipality}</a>
                                    </li>
                                )
                            })
                        }
                    </div>
                </ul>
            </div>
            <div
            >
                <div>
                    <label className="form-item form-item-label" htmlFor="farm">
                        Nome da fazenda
                    </label>
                    <input
                        className="form-item"
                        type="text"
                        list="farms_list"
                        id="farm"
                        value={searchedFarm}
                        onBlur={()=> {
                            if (!onDropDown)
                                removeFarmDropDown()
                        }}
                        // onBlur={event => ()=> {
                        //     console.log('blurred')
                        //     event.target.click()
                        //     removeFarmDropDown()
                        // }}
                        onInput={(event) => setSearchedFarm(event.target.value)}
                        autoComplete="off"
                     />
                </div>
                <div className="farm-list pos-relative">
                <ul id="farms_list" className="overlay">
                    <div
                        className="dropdown-container"
                        onMouseEnter={()=> {
                            setOnDropDown(true)
                        }}
                        onMouseLeave={()=> {
                            setOnDropDown(false)
                        }}
                    >
                        {
                            farmsList.map((farm) => {
                                return (
                                    <li
                                        onClick={(event) => {
                                            farmSelectedHandler(farm)
                                        }}
                                    >
                                        <a>{farm}</a>
                                    </li>
                                )
                            })
                        }
                    </div>
                </ul>
            </div>
            </div>
            <div className="farm-selection-container">
                <p className="x-small-text">
                    Inclui o seguinte:
                </p>
                {selectedParcels.length > 0 &&
                    <div
                        id="farms-selection-area" className="farms-selection">
                        {
                            selectedParcels.map((parcel, index) => {
                                return (
                                    <>
                                <span>
                                        <input
                                            className="farm-checkbox pointer-cursor"
                                            id={parcel?.parcelId}
                                            key={parcel?.parcelId}
                                            name={parcel?.parcelName}
                                            type="checkbox"
                                            checked={parcel?.checked}
                                            onChange={(event) => parcelToggleHandler(index,
                                                parcel?.parcelId,
                                                event.target.checked)}
                                        />
                                        <span className="ml-5 x-small-text">
                                            {parcel?.parcelName}
                                        </span>
                                    </span>
                                        <br/>
                                    </>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </>
    )
}
import {Grid} from "@mui/material";
import skoog from "../../assets/skoog-icon.jpeg"
import {VIEWS} from "../../constants/homepage";


export const Navigators = ({
    currentView,
    setCurrentView
                           })=> {
    return (
        <div className="bottom-buttons">
            <div className="navigator-item">
                {currentView !== VIEWS.LOGGED_IN_FARMS_VIEW &&
                    <>
                        <button className="ml-5"
                           onClick={()=> setCurrentView(VIEWS.LOGGED_IN_FARMS_VIEW)}
                        >
                            Go to my farms
                        </button>
                    </>
                }
            </div>
            <div className="navigator-item">
                {currentView !== VIEWS.LOGGED_IN_FARMS_SEARCH &&
                    <>
                        <button className="ml-5"
                           onClick={()=> setCurrentView(VIEWS.LOGGED_IN_FARMS_SEARCH)}
                        >
                            Go to farms search
                        </button>
                    </>
                }
            </div>
        </div>
    )
}
import './App.css';
import {HomePage} from "./components/home_page";
import {Header} from "./components/header";
import {ProjectsView} from "./components/projects";

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {useState} from "react";
import {Loader} from "./components/generic/loader";
import {AlertPage} from "./components/generic/alert";
import {AppContext} from "./context/AppContext";


function App() {
    const [showPopup, setShowPopup] = useState(false)
    const [popupType, setPopupType] = useState("info")
    const [popupMessage, setPopupMessage] = useState("Request Successfull")
    const [loading, setLoading] = useState(false)

    const showPopupOnMap = (type = "info", message) => {
        setPopupMessage(message)
        setPopupType(type)
        setShowPopup(true)
        setTimeout(() => {
            setShowPopup(false)
        }, 2000)
    }

    const toggleLoader = (load) => {
        setLoading(load)
    }


    const router = createBrowserRouter([
        {
            path: '/',
            element: <HomePage/>,
        },
        {
            path: '/projects_view',
            element: <ProjectsView/>,
        }
    ]);


    return (
        <div className="App">
            {loading &&
                <div className="overlay-loader">
                    <Loader loading={loading}/>
                </div>
            }
            <AlertPage
                showAlert={showPopup}
                type={popupType}
                message={popupMessage}
            />

            <AppContext.Provider value={{showPopupOnMap, toggleLoader}}>
                <Header/>
                <RouterProvider
                    router={router}
                />
            </AppContext.Provider>
        </div>
    );
}

export default App;

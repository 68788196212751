import "../styles/style.css";


export const Header = () => {
    return (
        <div>
            <div className="header">
                <div id="innerheader" className="inner-header">
                    <a style={{flex: 2}} href="https://meucarbono.com" target="_blank">
                        meucarbono.com
                    </a>
                    <div style={{flex: 3}} className="flex justify-content-start align-text-center menutext">
                        <div className="thin-text full-width header-text flex justify-contect-center align-text-center">
                            <a style={{flex: 1}} className="bold-text" href="https://www.meucarbono.com/o-mercado-de-carbono">
                                O Mercado de Carbono
                            </a>
                            <a style={{flex: 1}} className="" href="https://www.meucarbono.com/como-participar">
                                Como Participar
                            </a>
                            {/*<a className="mx-2" href="https://www.meucarbono.com/quem-somos">*/}
                            {/*    Quem Somos*/}
                            {/*</a>*/}
                            <button style={{flex: 1}} className="ml-5 skoog-button">
                                Cadastre-se →
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
}
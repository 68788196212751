import React, {useState} from "react";
import {loginUser} from "../../services/auth_service";


export const Login = ({
                             handleSuccess = () => {
                             },
                             handleFailure
                         }) => {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')

    const handleLoginSubmit = async () => {
        const form = new FormData()
        form.append('username', name)
        form.append('password', password)
        try {
            const res = await loginUser(form)
            if (res?.status === 200) {
                resetFields()
                handleSuccess(res?.data)
            } else {
                handleFailure(res?.response?.data?.message || "User Login Failed")
            }
        }
        catch (err) {
            handleFailure(err?.message)
        }
    }

    const resetFields = () => {
        setName('')
        setPassword('')
    }

    return (
        <div>
            <form
                onSubmit={(event) => event.preventDefault()}
                className="m-10">
                <label>E-mail*</label>
                <input
                    className="full-width"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required={true}
                />
                <br/>
                <br/>
                <label>Senha*</label>
                <br/>
                <input
                    className="full-width"
                    name="password"
                    required={true}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <br/>
                <br/>
                <div className="registration-submit-btn-container">
                    <input
                        className="skoog-button"
                        type="submit"
                        value="Enviar"
                        onClick={handleLoginSubmit}
                    />
                </div>
            </form>
        </div>
    )
}
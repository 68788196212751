export const formatParcelsDetails = (farmsData, selectedParcels=null) => {
    return farmsData.features.map(farm => {
        return {
            parcelId: farm?.properties?.pk,
            parcelName: farm?.properties?.nome_area,
            checked: selectedParcels === null ? true : farm?.properties?.pk
        }
    })
}


export const prepareParcelsList = (farmsData) => {
    const listOfFarms = []
    for (const farmFeature of farmsData.features) {
        listOfFarms.push({
            ...farmsData,
            features: [farmFeature]
        })
    }
    return listOfFarms
}


import {BACKEND_URL} from "./app";


export const getAreas = `${BACKEND_URL}/landowner/api/areas`
export const setFarms = `${BACKEND_URL}/landowner/api/areas/set_farms`
export const userFarms = `${BACKEND_URL}/landowner/api/areas/get_user_farms`
export const getParcels = `${BACKEND_URL}/landowner/api/areas/get_farms`
export const selectMunicipality = `${BACKEND_URL}/landowner/api/areas/select_municipality`
export const getUserId = `${BACKEND_URL}/landowner/user/userid`
export const uploadParcelImages = `${BACKEND_URL}/landowner/project-image`

export const register = `${BACKEND_URL}/landowner/auth/register`
export const login = `${BACKEND_URL}/landowner/auth/login`
export const logout = `${BACKEND_URL}/landowner/auth/logout`

export const project = `${BACKEND_URL}/landowner/project`
export const csvParcels = `${BACKEND_URL}/landowner/csv-parcels`
import CircularProgress from "@mui/material/CircularProgress";
import {Box} from "@mui/material";


export const Loader = ({
                           loading = false
                       }) => {
    return (
        loading ?
            <Box sx={{display: 'flex'}}>
                <CircularProgress color="warning"/>
            </Box>
            :
            <></>
    )
}
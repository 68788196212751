import React, {useState, useEffect, useContext} from "react";
import "../styles/homepage.css"
import "../styles/style.css"
import {MapController} from "./map";
import {Inputs} from "./map/inputs";
import userIcon from "../assets/Icon4.png";
import {
    get_farms,
    get_municipalities,
    get_parcels,
    getUserFarms,
    select_municipality,
    set_farms, upload_parcel_image
} from "../services/inputs_service";
import {useCookies} from 'react-cookie'
import {VIEWS} from "../constants/homepage";
import {Register} from "./auth/register";
import {formatParcelsDetails, prepareParcelsList} from "../utils/data_formatting";
import {AlertPage} from "./generic/alert";
import {Login} from "./auth/login";
import {getCookie} from "../utils/requests";
import {logoutUser, userId} from "../services/auth_service";
import {Navigators} from "./map/navigators";
import {MyFarms} from "./map/my_farms";
import {setFarms} from "../constants/api_endpoints";
import {useMediaQuery} from "@mui/material";
import SelectVariants, {DropDown, DropDownSimple} from "./generic/dropdown";
import {getAllProjects} from "../services/project_service";
import {Loader} from "./generic/loader";


export const HomePage = () => {
    const [selectedMuni, setSelectedMuni] = useState('')
    const [searchedMuni, setSearchedMuni] = useState('')
    const [municipalitiesList, setSearchedMunicipalitiesList] = useState([])
    const [farmsList, setFarmsList] = useState([])
    const [codigosList, setCodigosList] = useState([])
    const [searchedFarm, setSearchedFarm] = useState('')
    const [selectedFarm, setSelectedFarm] = useState('')
    const [selectedParcelsForMap, setSelectedParcelsForMap] = useState([])
    const [selectedParcelsForSearch, setSelectedParcelsForSearch] = useState([])
    const [selectedParcelsForView, setSelectedParcelsForView] = useState([])
    const [currentView, setCurrentView] = useState(VIEWS.FARMS)
    const [map, setMap] = useState(null)
    const [toggledParcel, setToggledParcel] = useState({
        id: null,
        index: null,
        checked: null
    })
    const [parcelsGeoData, setParcelsGeoData] = useState({
        old: [],
        new: []
    })
    const [parcelsGeoDataForSearch, setParcelsGeoDataForSearch] = useState([])
    const [parcelsGeoDataForView, setParcelsGeoDataForView] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [popupType, setPopupType] = useState("info")
    const [popupMessage, setPopupMessage] = useState("Request Successfull")
    const mobileScreen = useMediaQuery('(max-width: 500px)')
    const [selectedParcelForUpload, setSelectedParcelForUpload] = useState(null)
    const [uploadedImages, setUploadedImages] = useState([])
    const [selectedProject, setSelectedProject] = useState(null)
    const [allProjects, setAllProjects] = useState([])
    const [imagesUploading, setImagesUploading] = useState(false)
    const [timeoutId, setTimeOutId] = useState(null)
    const [loading, setLoading] = useState(false)

    const username = getCookie('username')
    const sessionId = getCookie('sessionid')


    const [cookies, setCookie] = useCookies(['username', 'userid'])

    useEffect(() => {
        if ([null, "null", "undefined"].includes(username))
            setCurrentView(VIEWS.FARMS)
        else
            setCurrentView(VIEWS.LOGGED_IN_FARMS_SEARCH)
    }, [cookies, username])

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId)
            setSearchedMunicipalitiesList([])
        }
        const timeOutId = setTimeout(async () => {
            if (searchedMuni.length && searchedMuni !== selectedMuni) {
                const res = await get_municipalities(searchedMuni)
                if (res.status === 200)
                    setSearchedMunicipalitiesList(res.data)
            }
        }, 500)
        setTimeOutId(timeOutId)
    }, [searchedMuni])

    useEffect(() => {
        ;(async () => {
            if (![null, "null", "undefined"].includes(username) && (!parcelsGeoDataForView.length || !selectedParcelsForView.length)) {
                const userFarmsRes = await getUserFarms(username)
                if (userFarmsRes?.status === 200) {
                    const data = userFarmsRes?.data
                    const geoData = JSON.parse(data?.all_parcels)
                    setParcelsGeoDataForView(prepareParcelsList(geoData))
                    setSelectedParcelsForView(geoData.features.map((parcel) => {
                        return {
                            parcelId: parcel?.properties?.pk,
                            parcelName: parcel?.properties?.nome_area,
                            checked: true
                        }
                    }))
                }
            }
            if (currentView === VIEWS.LOGGED_IN_FARMS_VIEW) {
                setParcelsGeoData(prevState => {
                    return {
                        old: prevState.new,
                        new: parcelsGeoDataForView
                    }
                })
                setSelectedParcelsForMap(selectedParcelsForView)
            } else if ([VIEWS.LOGGED_IN_FARMS_SEARCH, VIEWS.FARMS].includes(currentView)) {
                setParcelsGeoData(prevState => {
                    return {
                        old: prevState.new,
                        new: parcelsGeoDataForSearch
                    }
                })
                setSelectedParcelsForMap([...selectedParcelsForSearch])
            }
        })()
    }, [currentView, selectedParcelsForView, selectedParcelsForSearch, parcelsGeoDataForSearch,
        parcelsGeoDataForView
    ])

    useEffect(() => {
        ;(async () => {
            if (searchedFarm.length && searchedFarm !== selectedFarm) {
                const res = await get_farms(searchedFarm)
                if (res?.status === 200) {
                    setFarmsList(res?.data?.farms)
                    setCodigosList(res?.data?.codigos)
                }
            }
        })()
    }, [searchedFarm])

    useEffect(() => {
        ;(async () => {
            const projectsResp = await getAllProjects()
            if (projectsResp?.status === 200) {
                const projectsData = projectsResp?.data.map(project => {
                    return {
                        value: project.id,
                        label: project.name
                    }
                })
                setAllProjects(projectsData)
            }
        })()
    }, [])

    const municipalitySelectionHandler = async (muni) => {
        setSelectedMuni(muni)
        setSearchedMuni(muni)
        setSearchedMunicipalitiesList([])
        await select_municipality(muni)
    }

    const farmSelectedHandler = async (farm) => {
        setSelectedFarm(farm)
        setSearchedFarm(farm)
        setFarmsList([])
        setLoading(true)
        const codigo = codigosList[farmsList.indexOf(farm)]
        if (codigo) {
            const res = await get_parcels(codigo)
            if (res?.status === 200) {
                console.log('res is ', res)
                setSelectedParcelsForSearch(formatParcelsDetails(JSON.parse(res?.data)))
                setParcelsGeoDataForSearch(prepareParcelsList(JSON.parse(res?.data)))
            }
        }
        setLoading(false)
    }

    const parcelToggleHandler = (index, id, checked) => {
        if (selectedParcelsForMap.length) {
            const newParcels = [...selectedParcelsForMap]
            newParcels[index] = {
                ...newParcels[index],
                checked
            }
            if ([VIEWS.LOGGED_IN_FARMS_SEARCH, VIEWS.FARMS].includes(currentView)) {
                setSelectedParcelsForSearch(newParcels)
            } else if (currentView === VIEWS.LOGGED_IN_FARMS_VIEW) {
                setSelectedParcelsForView(newParcels)
            }

            setSelectedParcelsForMap(newParcels)
            setToggledParcel({
                id,
                index,
                checked
            })
        }
    }

    const showPopupOnMap = (type = "info", message) => {
        setPopupMessage(message)
        setPopupType(type)
        setShowPopup(true)
        setTimeout(() => {
            setShowPopup(false)
        }, 2000)
    }

    const handleSuccessfulRegister = async (message, user_id) => {
        // showPopupOnMap("success", message)
        await set_farms(selectedParcelsForSearch.filter(parcel => parcel.checked).map(parcel => parcel.parcelId), user_id)
        setCurrentView(VIEWS.AFTER_REGISTER)
    }

    const handleLoginSuccess = async (data) => {
        showPopupOnMap("success", data?.message || "Login Successful")
        setCookie('username', data?.data?.user_name)
    }

    const handleFailedRegister = (message) => {
        showPopupOnMap("error", message)
    }

    const handleFailedLogin = (message) => {
        showPopupOnMap("error", message)
    }

    const handleLogout = async () => {
        const res = await logoutUser()
        if (res?.status === 200) {
            showPopupOnMap("success", res?.data?.message)
            setCookie('username', null)
        }
    }

    const handleMapLayerClickForUpload = (layerInfo) => {
        setSelectedParcelForUpload(layerInfo?.pk)
    }

    const handleImagesUploadChange = (event) => {
        console.log('file inpt is ', event)
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        setUploadedImages(chosenFiles)
        console.log("chosien files are ", chosenFiles)
    }

    const uploadParcelImages = async () => {
        const data = new FormData
        for (const file of uploadedImages) {
            data.append('file', file)
        }
        data.append('selected_parcel', selectedParcelForUpload)
        data.append('project_id', selectedProject)
        setImagesUploading(true)
        const uploadRes = await upload_parcel_image(data)
        setImagesUploading(false)
        if (uploadRes?.data == true)
            showPopupOnMap("success", "Images Uploaded successfully")
        else
            showPopupOnMap("error", uploadRes?.data?.message || "Images did not upload successfully")
        setUploadedImages([])
        setSelectedProject(null)
        setSelectedParcelForUpload(null)
        console.log("upload res is ", uploadRes)
    }

    return (
        <div className="pos-relative">
            {loading &&
                <div className="overlay-loader">
                    <Loader loading={loading}/>
                </div>
            }
            <div className="homepage app-font">
                <div className={`left-panel ${mobileScreen ? 'mobile' : ''}`}>
                    <div id="user-login" className="user-panel">
                        <img
                            className="user-icon"
                            src={userIcon}
                            alt="user-icon"
                        >
                        </img>
                        <div className="reg-login-text">
                            {
                                [VIEWS.LOGGED_IN_FARMS_SEARCH, VIEWS.LOGGED_IN_FARMS_VIEW].includes(currentView) ?
                                    <>
                                    <span className="login-text pointer flex align-text-center">
                                    Logged In as {username}
                                </span>
                                        <span className="justify-content-end">
                                            <button
                                                onClick={handleLogout}
                                                className="logout-button">
                                                logout
                                            </button>
                                        </span>
                                    </>
                                    :
                                    [VIEWS.FARMS, VIEWS.REGISTER, VIEWS.AFTER_REGISTER].includes(currentView) ?
                                        <span
                                            onClick={() => setCurrentView(VIEWS.LOGIN)}
                                            className="login-text pointer">
                                            Entrar na conta existente
                                        </span>
                                        :
                                        <span
                                            onClick={() => setCurrentView(VIEWS.FARMS)}
                                            className="login-text pointer flex align-text-center">
                                            Ir para fazendas
                                        </span>
                            }
                        </div>
                    </div>
                    <br/>
                    <br/>
                    {
                        [VIEWS.FARMS, VIEWS.LOGGED_IN_FARMS_SEARCH].includes(currentView) ?
                            <>
                                <form id="form-farm" className="farm-selection-form">
                                    <Inputs
                                        removeMuniDropDown={() => setSearchedMunicipalitiesList([])}
                                        removeFarmDropDown={() => setFarmsList([])}
                                        municipalitySelectedHandler={municipalitySelectionHandler}
                                        farmSelectedHandler={farmSelectedHandler}
                                        municipalitiesList={municipalitiesList}
                                        searchedMuni={searchedMuni}
                                        setSearchedMuni={setSearchedMuni}
                                        setSearchedFarm={setSearchedFarm}
                                        searchedFarm={searchedFarm}
                                        farmsList={farmsList}
                                        selectedParcels={selectedParcelsForSearch}
                                        parcelToggleHandler={parcelToggleHandler}
                                    />
                                </form>
                                <div id="confirmar-button-container" className="confirmar-button-container">
                                    <button
                                        id="confirmar-button"
                                        className={`skoog-button ${!selectedParcelsForSearch.length ? 'disabled' : ''}`}
                                        disabled={selectedParcelsForSearch.length === 0}
                                        onClick={async () => {
                                            if ([null, "null", "undefined"].includes(username))
                                                setCurrentView(VIEWS.REGISTER)
                                            else {
                                                const userRes = await userId(username)
                                                console.log("usr res is ", userRes)
                                                setLoading(true)
                                                await set_farms(selectedParcelsForSearch.filter(parcel => parcel.checked).map(parcel => parcel.parcelId), userRes.data.data.id)
                                                setLoading(false)
                                                showPopupOnMap('success', 'New farms added to user')
                                            }
                                        }}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </>
                            :
                            currentView === VIEWS.LOGGED_IN_FARMS_VIEW ?
                                <MyFarms
                                    setToggledParcel={parcelToggleHandler}
                                    selectedParcels={selectedParcelsForView}
                                />
                                :
                                currentView === VIEWS.REGISTER ?
                                    <>
                                        <Register
                                            handleSuccess={handleSuccessfulRegister}
                                            handleFailedRegister={handleFailedRegister}
                                        />
                                    </>
                                    :
                                    currentView === VIEWS.AFTER_REGISTER ?
                                        <div className="text-white m-10 small-text">
                                            <p className="bold-text">
                                                Obrigado pelo interesse.
                                            </p>
                                            <p>
                                                Um email foi enviado para o endereço
                                                fornecido. Clique no link enviado para
                                                completar o cadastro.
                                            </p>
                                            <p>
                                                Nossa equipe entrará em contato para discutir
                                                os próximos passos.
                                            </p>
                                        </div>
                                        :
                                        currentView === VIEWS.LOGGED_IN_WITH_LINK ?
                                            <div className="text-white m-10 small-text">
                                                <p className="bold-text">
                                                    E-mail confirmado com sucesso.
                                                </p>
                                                <p>
                                                    Parabéns por se juntar à nossa plataforma!
                                                    Aqui você pode ver uma lista de suas parcelas reivindicadas até
                                                    agora.
                                                    Estamos avaliando o potencial de sustentabilidade do seu terreno
                                                    e como você pode lucrar conservando,
                                                    recuperando ou melhorando. Os nossos agentes no terreno entrarão
                                                    em contacto consigo para visitar o terreno e dar os próximos
                                                    passos. Por favor fique atento!
                                                </p>
                                            </div>
                                            :
                                            [VIEWS.LOGGED_OUT, VIEWS.LOGIN].includes(currentView) ?
                                                <>
                                                    <Login
                                                        handleSuccess={handleLoginSuccess}
                                                        handleFailure={handleFailedLogin}
                                                    />
                                                </>
                                                :
                                                <div>
                                                    Nothing
                                                </div>
                    }
                    {![null, "null", "undefined"].includes(username) &&
                        <>
                            <Navigators
                                currentView={currentView}
                                setCurrentView={setCurrentView}
                            />
                            {
                                currentView === VIEWS.LOGGED_IN_FARMS_VIEW &&
                                <div className="m-10">
                                    {
                                        !selectedParcelForUpload ?
                                            <p className="small-text bold-text">
                                                Click on the parcel to enable image upload functionality
                                            </p>
                                            :
                                            <>
                                                <DropDownSimple
                                                    options={allProjects}
                                                    optionLabel="Select Project"
                                                    setSelectedOption={setSelectedProject}
                                                />
                                                <p className="small-text bold-text">
                                                    Selected parcel: {selectedParcelForUpload}
                                                </p>
                                                <input type="file" multiple name="file"
                                                       id="file"
                                                       accept=".jpeg, .png"
                                                       onChange={(event) => handleImagesUploadChange(event)}
                                                       className="myclass"/>
                                                <label htmlFor="file">Choose images to upload</label>
                                                <p>
                                                    Uploaded Images
                                                </p>
                                                {
                                                    uploadedImages.map((image) => {
                                                        return (
                                                            <p className="small-text">
                                                                {image.name}
                                                            </p>
                                                        )
                                                    })
                                                }
                                                <button
                                                    disabled={!uploadedImages.length || !selectedProject || imagesUploading}
                                                    onClick={uploadParcelImages}
                                                >
                                                    {
                                                        imagesUploading ?
                                                            <>
                                                                <i className="fa fa-circle-o-notch fa-spin"></i>
                                                                Uploading
                                                            </>
                                                            :
                                                            <>
                                                                Upload
                                                            </>
                                                    }
                                                </button>
                                            </>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
                <MapController
                    mapLayerClickedHandler={handleMapLayerClickForUpload}
                    parcelsGeoData={parcelsGeoData}
                    toggledParcel={toggledParcel}
                />
            </div>
            <AlertPage
                showAlert={showPopup}
                type={popupType}
                message={popupMessage}
            />
        </div>
    )
}